<template>
  <div
    class="formdata"
    style="padding: 20px;"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
    >
      <div class="title">
        <div class="titleType">
          订单信息
        </div>
      </div>
      <el-row :gutter="60">
        <el-col :span="8">
          <el-row>
            <el-form-item label="患者姓名:">
              {{ form.patientName }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="手机号:">
              {{ form.patientMobile }}
              <!-- <span v-if="!allPhone">{{ form.patientMobile|hidePhone }}</span>
              <span v-else>{{ form.patientMobile }}</span>
              <i
                class="el-icon-view"
                @click="isShow()"
              /> -->
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="身份证号:">
              <!-- <span v-if="!allIdCard">{{ form.patientIdCard|hideIdCard }}</span>
              <span v-else>{{ form.patientIdCard }}</span>
              <i
                class="el-icon-view"
                @click="isShowIdCard"
              /> -->
              {{ form.patientIdCard }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="性别:">
              {{ form.patientSex==1?'男':'女' }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="年龄:">
              {{ form.patientAge }}岁
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <el-form-item label="健康咨询订单号:">
              {{ form.resourceNo }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="咨询时间:">
              {{ form.consultationTime }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="支付费用:">
              {{ form.doctorSettlementFee }}
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="确认状态:">
              {{ form.confirmStatus?'已抢单':'未抢单' }}
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
      <div class="title">
        <div class="titleType">
          咨询内容
        </div>
      </div>
      <div class="contentStyle">
        {{ form.consultationContent }}
      </div>
      <!-- <el-form-item :label="form.consultationContent+':'" /> -->
      <div class="flex">
        <template v-if="form.consultationImageUrl.length>0">
          <div
            v-for="(item,index) in form.consultationImageUrl"
            :key="index"
          >
            <el-image
              v-if="item"
              style="width: 150px; height: 150px; margin-right: 10px;"
              :src="item"
              fit="scale-down"
              :preview-src-list="form.consultationImageUrl"
              @click="solveRefresh()"
            />
          </div>
        </template>
      </div>
      <div class="btn">
        <el-button
          type="primary"
          @click="$router.back()"
        >
          返回
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'Details',
    data() {
      return {
        allIdCard:false, // 是否展示完全的身份证
        allPhone:false, // 是否展示完全的电话号码
        form:{
          doctorSettlementFee:'',
          confirmStatus:'',
          consultationTime:'',
          patientName:'',
          patientMobile:'',
          patientSex:'',
          patientAge:'',
          resourceNo	:'',
          patientIdCard:'',
          consultationContent:'',
          consultationImageUrl:[]
        },
      };
    },
    computed: {
      ...mapState('basicDictionary', {
        allOrganList:state => state.allOrganList
      }),
      isView() {
        return this.$route.query.type;
      }
    },
    watch: {},
    mounted() {
      console.log(typeof this.$route.query.type);
      this.getOrderDetail();
    },
    methods: {
      // ...mapActions('orderManage', ['queryDetail']),
      // el-image点击图片页面卡顿问题
      solveRefresh() {
        document.body.style = '';
      },
      // 电话号码显示隐藏切换
      isShow() {
        this.allPhone = !this.allPhone;
      },
      // 身份证中间几位显示隐藏切换
      isShowIdCard() {
        this.allIdCard = !this.allIdCard;
      },
      // 订单详情
      getOrderDetail() {
        const a = JSON.parse(this.$route.query.detail);
        console.log(a, 'a');
        this.form = {
          doctorSettlementFee:a.doctorSettlementFee,
          confirmStatus:a.confirmStatus,
          consultationTime:a.consultationTime,
          patientName:a.patientName,
          patientMobile:a.patientMobile,
          patientSex:a.patientSex,
          patientAge:a.patientAge,
          resourceNo:a.resourceNo,
          patientIdCard:a.patientIdCard,
          consultationContent:a.consultationContent,
          consultationImageUrl:a.consultationImageUrl.split(',')
        };
      },
    }
  };
</script>

<style lang="scss" scoped>
.formdata {
  label {
    font-size: 14px;
    color: #606266;
  }

  .input_el {
    width: 30%;
  }
}
.labelStyle { margin-right: 10px; }

.title {
  height: 40px;
  margin-bottom: 10px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
}

.btn {
  margin-left: 100px;
}

.flex {
  display: flex;
  margin: 10px 100px;
}

.contentStyle {
  margin: 10px 100px;
}
</style>
